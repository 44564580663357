.footer-background {
  background-image: url("../../assets//pictures/footer\ image.png");
  height: 100%;
}
.footer-left-col{
  border-right: solid;
  padding-right: 10%;
  color: white;
}
.footer-left-col h2 {
  text-align: center;
  margin-top: 5%;
  margin-bottom: 8%;
  color: white;
}

.footer-text-box {
  width: 100%;
  margin-bottom: 3%;
}
.footer-message-box{
  width: 100%;
  height: 150px;
}
/* .footer-message-box::placeholder{
  margin-top: 0px!important;
} */
.footer-submit-button{
  margin-top: 10px;
  text-align: right;
  margin-right: 0px;
  width: 100%;
}

.footer-horizontal-line {
  height: 2px;
  margin-top: 2px;
  margin-bottom: 1rem;
  width: 100%;
  background-color: rgb(234, 230, 230);
}
.footer-copy-rights{
  color: white;
  text-align: right;
   margin-top: -10px;
   font-size: 14px;
  }

.footer-right-col {
  text-align: center;
}
.footer-right-col img {
  margin-top: 18%;
  width: 30%;
}

.footer-right-col h3 {
  margin-top: 15%;
  margin-bottom: 4rem;
  color: white;
}

.footer-right-col p {
  margin-bottom: 6px;
  color: white;
}

.footer-link {
  text-decoration: none;
}
