@media only screen and (min-width: 800px) {
  .home-landing-container {
    background-image: url("../../assets/pictures/home-large-image.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .home-landing-content {
    margin-top: 25%;
    color: white;
    height: 400px;
  }

  .home-landing-left-border {
    border-left: solid;
    padding-left: 2%;
  }

  .home-landing-content h1 {
    font-size: 60px;
  }

  .home-landing-content p {
    padding-top: 2%;
    font-size: 18px;
  }

  .home-landing-content img {
    margin-top: 40px;
    width: 75px;
    margin-right: 25px;
  }

  .home-landing-icons img {
    color: white;
    width: 55px;
    margin-top: 60px;
    margin-right: 30px;
    margin-left: 10px;
  }

  .home-small-image {
    height: 400px;
    width: 350px;
    margin-left: 75%;
    margin-top: 30%;
  }

  /* part2 */

  .home-content-risk {
    display: block;
    width: fit-content;
    margin-top: 25%;
    margin-left: auto;
    margin-right: auto;
  }

  .home-content-risk img {
    width: 70px;
    margin-top: -40px;
  }

  .home-content-risk h2 {
    font-size: 30px;
    padding-top: 10%;
    padding-bottom: 3%;
    color: rgb(14, 14, 141);
  }
  .home-content-risk p {
    font-size: 16px;
    color: rgb(14, 14, 141);
  }

  .risk-img {
    display: block;
    width: 60%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-content-stability {
    display: block;
    width: fit-content;
    margin-top: 25%;
    margin-left: 120px;
    margin-right: auto;
    margin-bottom: 160px;
    color: rgb(14, 14, 141);
  }
  .home-content-stability img {
    width: 80px;
    margin-top: -40px;
  }
  .home-content-stability h2 {
    font-size: 25px !important;
    padding-top: 8%;
    padding-bottom: 3%;
    font-size: 30px;
    color: rgb(14, 14, 141);
  }

  .home-content-stability p {
    font-size: 16px;
    color: rgb(14, 14, 141);
  }

  .khalijiImage {
    display: block;
    margin-top: 50px;
    margin-right: 100px;
    margin-left: auto;
    width: 60%;
  }

  .home-container-full-blue {
    background-image: url("../../assets//pictures/towers-pic.png");
    height: 90%;
  }

  .home-content-blue {
    text-align: center;
    color: white;
  }

  .home-content-blue h1 {
    padding-top: 6rem;
    padding-bottom: 1rem;
    font-size: 50px;
  }
  .home-content-blue p {
    font-size: 20px;
    padding-bottom: 3rem;
  }

  .home-content-blue img {
    margin-top: -70px;
    width: 200px;
  }

  .home-content-time {
    text-align: center;
    margin-top: 5rem;
    color: rgb(14, 14, 141);
  }
  .home-content-time h2 {
    font-size: 40px;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .home-content-time p {
    font-size: 20px;
  }
  .home-horizontal-line {
    height: 2px;
    margin-top: 3rem;
    background-color: rgb(234, 230, 230);
  }
  .home-brainstorm-image {
    display: block;
    width: 60%;
    margin-top: 50px;
    margin-right: 100px;
    margin-left: auto;
  }
  .home-content-brainstorm {
    padding-top: 20%;
    color: rgb(14, 14, 141);
  }
  .home-content-brainstorm img {
    width: 90px;
    padding-bottom: 1rem;
  }
  .home-content-brainstorm h2 {
    font-size: 30px;
    margin-top: 10px;
  }
  .home-content-brainstorm p {
    padding-top: 1rem;
    font-size: 17px;
  }

  .home-mission-image {
    margin-top: 10rem;
    color: rgb(14, 14, 141);
    text-align: center;
  }
  .home-mission-image img {
    padding-bottom: 3rem;
  }
  .home-mission-image h2 {
    margin-top: -1rem;
  }
  .home-mission-content {
    margin-top: 5rem;
    color: rgb(14, 14, 141);
  }

  .home-mission-content h3 {
    margin-top: 1rem;
    font-size: 30px;
  }
  .home-mission-content p {
    font-size: 18px;
    margin-bottom: -2rem;
  }
  .meeting-image {
    display: block;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    width: 60%;
  }
  .home-content-meeting img {
    margin-top: -20px;
    width: 90px;
  }
  .home-content-meeting {
    margin-top: 7rem;
    color: rgb(14, 14, 141);
  }
  .home-content-meeting h2 {
    font-size: 35px;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .home-content-meeting p {
    font-size: 20px;
  }
  .home-people-meeting {
    background-image: url("../../assets//pictures/people-grey-pic.png");
    text-align: center;
    height: 90%;
    color: white;
  }
  .home-people-meeting img {
    width: 200px;
    margin-bottom: -3rem;
  }
  .home-people-meeting h1 {
    font-size: 50px;
    margin-top: 7rem;
  }
  .home-people-meeting p {
    font-size: 20px;
    padding-top: 1rem;
    padding-bottom: 5rem;
  }

  .home-filing-content {
    margin-top: 10rem;
    margin-right: 5%;
    padding-top: 5%;
    height: 350px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    color: rgb(163, 162, 162);
  }
  .home-filing-content h2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: rgb(14, 14, 141);
  }
  .home-filing-content p {
    font-size: 15px;
    color: rgb(163, 162, 162);
    font-weight: 500;
  }
  .home-filing-content img {
    width: 75px;
  }

  .home-target-content {
    margin-top: 10rem;
    height: 350px;
    margin-left: 5%;
    padding-top: 5%;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    color: rgb(163, 162, 162);
  }
  .home-target-content h2 {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    color: rgb(14, 14, 141);
  }
  .home-target-content p {
    font-size: 15px;
    color: rgb(163, 162, 162);
    font-weight: 500;
  }
  .home-target-content img {
    width: 90px;
  }
}

@media only screen and (min-width: 1200px) {
  .home-landing-container {
    background-image: url("../../assets/pictures/home-large-image.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .home-landing-content {
    margin-top: 25%;
    color: white;
    height: 500px;
  }

  .home-landing-left-border {
    border-left: solid;
    padding-left: 2%;
  }

  .home-landing-content h1 {
    font-size: 60px;
  }

  .home-landing-content p {
    padding-top: 2%;
    font-size: 18px;
  }

  .home-landing-content img {
    margin-top: 40px;
    width: 75px;
    margin-right: 25px;
  }

  .home-landing-icons img {
    color: white;
    width: 55px;
    margin-top: 60px;
    margin-right: 30px;
    margin-left: 10px;
  }

  .home-small-image {
    height: 400px;
    width: 350px;
    margin-left: 75%;
    margin-top: 30%;
  }

  /* part2 */

  .home-content-risk {
    display: block;
    width: fit-content;
    margin-top: 25%;
    margin-left: auto;
    margin-right: auto;
  }

  .home-content-risk img {
    width: 70px;
    margin-top: -40px;
  }

  .home-content-risk h2 {
    font-size: 30px;
    padding-top: 10%;
    padding-bottom: 3%;
    color: rgb(14, 14, 141);
  }
  .home-content-risk p {
    font-size: 16px;
    color: rgb(14, 14, 141);
  }

  .risk-img {
    display: block;
    width: 60%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-content-stability {
    display: block;
    width: fit-content;
    margin-top: 25%;
    margin-left: 120px;
    margin-right: auto;
    margin-bottom: 160px;
    color: rgb(14, 14, 141);
  }
  .home-content-stability img {
    width: 80px;
    margin-top: -40px;
  }
  .home-content-stability h2 {
    font-size: 25px !important;
    padding-top: 8%;
    padding-bottom: 3%;
    font-size: 30px;
    color: rgb(14, 14, 141);
  }

  .home-content-stability p {
    font-size: 16px;
    color: rgb(14, 14, 141);
  }

  .khalijiImage {
    display: block;
    margin-top: 50px;
    margin-right: 100px;
    margin-left: auto;
    width: 60%;
  }

  .home-container-full-blue {
    background-image: url("../../assets//pictures/towers-pic.png");
    height: 90%;
  }

  .home-content-blue {
    text-align: center;
    color: white;
  }

  .home-content-blue h1 {
    padding-top: 6rem;
    padding-bottom: 1rem;
    font-size: 50px;
  }
  .home-content-blue p {
    font-size: 20px;
    padding-bottom: 3rem;
  }

  .home-content-blue img {
    margin-top: -70px;
    width: 200px;
  }

  .home-content-time {
    text-align: center;
    margin-top: 5rem;
    color: rgb(14, 14, 141);
  }
  .home-content-time h2 {
    font-size: 40px;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .home-content-time p {
    font-size: 20px;
  }
  .home-horizontal-line {
    height: 2px;
    margin-top: 3rem;
    background-color: rgb(234, 230, 230);
  }
  .home-brainstorm-image {
    display: block;
    width: 60%;
    margin-top: 50px;
    margin-right: 100px;
    margin-left: auto;
  }
  .home-content-brainstorm {
    padding-top: 20%;
    color: rgb(14, 14, 141);
  }
  .home-content-brainstorm img {
    width: 90px;
    padding-bottom: 1rem;
  }
  .home-content-brainstorm h2 {
    font-size: 30px;
    margin-top: 10px;
  }
  .home-content-brainstorm p {
    padding-top: 1rem;
    font-size: 17px;
  }

  .home-mission-image {
    margin-top: 10rem;
    color: rgb(14, 14, 141);
    text-align: center;
  }
  .home-mission-image img {
    padding-bottom: 3rem;
  }
  .home-mission-image h2 {
    margin-top: -1rem;
  }
  .home-mission-content {
    margin-top: 5rem;
    color: rgb(14, 14, 141);
  }

  .home-mission-content h3 {
    margin-top: 1rem;
    font-size: 30px;
  }
  .home-mission-content p {
    font-size: 18px;
    margin-bottom: -2rem;
  }
  .meeting-image {
    display: block;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    width: 60%;
  }
  .home-content-meeting img {
    margin-top: -20px;
    width: 90px;
  }
  .home-content-meeting {
    margin-top: 7rem;
    color: rgb(14, 14, 141);
  }
  .home-content-meeting h2 {
    font-size: 35px;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .home-content-meeting p {
    font-size: 20px;
  }
  .home-people-meeting {
    background-image: url("../../assets//pictures/people-grey-pic.png");
    text-align: center;
    height: 90%;
    color: white;
  }
  .home-people-meeting img {
    width: 200px;
    margin-bottom: -3rem;
  }
  .home-people-meeting h1 {
    font-size: 50px;
    margin-top: 7rem;
  }
  .home-people-meeting p {
    font-size: 20px;
    padding-top: 1rem;
    padding-bottom: 5rem;
  }

  .home-filing-content {
    margin-top: 10rem;
    margin-right: 5%;
    padding-top: 5%;
    height: 350px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    color: rgb(163, 162, 162);
  }
  .home-filing-content h2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: rgb(14, 14, 141);
  }
  .home-filing-content p {
    font-size: 15px;
    color: rgb(163, 162, 162);
    font-weight: 500;
  }
  .home-filing-content img {
    width: 75px;
  }

  .home-target-content {
    margin-top: 10rem;
    height: 350px;
    margin-left: 5%;
    padding-top: 5%;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    color: rgb(163, 162, 162);
  }
  .home-target-content h2 {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    color: rgb(14, 14, 141);
  }
  .home-target-content p {
    font-size: 15px;
    color: rgb(163, 162, 162);
    font-weight: 500;
  }
  .home-target-content img {
    width: 90px;
  }
}

@media only screen and (min-width: 1200px) {
  .home-landing-content {
    margin-top: 25%;
    color: white;
    height: 650px;
  }
}
