@media only screen and (max-width: 1200px) {
  .aboutUs-background {
    background-image: url("../../assets//pictures/about-us-background.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .aboutUs-header {
    text-align: center;
    color: rgb(14, 14, 141);
    margin-top: 20px;
  }
  .aboutUs-header h1 {
    font-size: 20px;
    margin-top: 0.5rem;
    margin-bottom: 40px;
  }
  .aboutUs-header img {
    width: 55px;
  }
  .tower-img {
    width: 100%;
    height: 100%;
  }
  .aboutUs-content p {
    font-size: 12px;
    color: rgb(111, 111, 111);
  }
  .aboutUs-content-our-team {
    text-align: center;
    margin-top: 2rem;
    color: rgb(14, 14, 141);
  }
  .aboutUs-content-our-team img {
    width: 50px;
  }
  .aboutUs-content-our-team h2 {
    font-size: 20px;
    padding-top: 0.5rem;
    padding-bottom: 0rem;
  }
  .aboutUs-content-our-team p {
    font-size: 12px;
    color: rgb(111, 111, 111);
  }
  .aboutUs-dotted-img {
    width: 50%;
    margin-top: 11rem;
    margin-left: 90%;
  }
}
