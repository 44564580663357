@media only screen and (max-width: 1200px) {
    .services-background {
        background-image: url("../../assets//pictures//beyond\ service\ background.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
      .services-landing {
        margin-top: 50px;
        text-align: center;
      }
      .services-landing img{
        width: 60px;
      }
      .services-landing h1 {
        padding-top: 20px;
        font-size: 25px;
        padding-bottom: 1rem;
        color: rgb(14, 14, 141);
      }
      .services-landing p {
        font-size: 15px;
        padding-bottom: 2rem;
        color: rgb(171, 169, 169);
      }
      
      /* ----------boxing section---------- */
      
      .services-treaty-box {
        text-align: center!important;
        max-width: 50%;
        margin-left: 25%;
        background-color: rgb(14, 14, 141) !important;
      }
      
      .services-treaty-box p {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        color: white;
      }
      .services-facultative-box {
        margin-top: 5%;
        margin-left: 25%;
        text-align: center;
        max-width: 50%;
        background-color: rgb(14, 14, 141) !important;
      }
      .services-facultative-box p {
        text-align: center;
        font-size: 19px;
        font-weight: 700;
        color: white;
      }
      .first-four-boxes {
        text-align: center;
        height: 110px;
      }
      
      .first-four-boxes p {
        margin-top: 10%;
        font-weight: 600;
        font-size: 15px;
        color: rgb(111, 111, 111);
      }
      .services-boxes-view-more {
        text-align: center;
      }
      .services-boxes-view-more p {
        font-weight: 600;
        font-size: 20px;
        color: rgb(111, 111, 111);
      }
      
      .services-modal{
        margin-top: 20px;
      }
      
}