@media only screen and (max-width: 1200px) {

    .footer-background {
        background-image: url("../../assets//pictures/footer\ image.png");
        height: 100%;
      }
      .footer-left-col{
        border-right:0ch;
        padding-right: 0;
        color: white;
      }
      .footer-left-col h2 {
        font-size: 18px;
        text-align: center;
        margin-top: 5%;
        margin-bottom: 8%;
        color: white;
      }
      
      .footer-text-box {
        width: 100%;
        height: 60%;
        margin-bottom: 3%;
      }
      .footer-message-box{
        width: 100%;
        margin-top: 0px;
        height: 95px;
      }
      /* .footer-message-box::placeholder{
        margin-top: 0px!important;
      } */
      /* .footer-submit-button{
    
        margin-top: 10px;
        text-align: right;
      } */
.footer-submit-button{
    margin-top: 10px;
    text-align: center;
    margin-right: 0px;
    width: 100%;
  }


      .footer-horizontal-line {
        height: 2px;
        margin-top: 2px;
        margin-bottom: 1rem;
        width: 100%;
        background-color: rgb(234, 230, 230);
      }
      .footer-copy-rights{
        color: white;
        text-align: right;
         margin-top: -11px;
         font-size: 10px;
        }
        .footer-copy-rights img{
            width: 40px;
        }
      
      .footer-right-col {
        text-align: center;
      }
      .footer-right-col img {
        margin-top: 20%;
        width: 50%;
      }
      
      .footer-right-col h3{
        margin-top: 10%;
        margin-bottom: 10%;
        font-size: 20px;
        color: white;
      }
      
      .footer-right-col p {
        font-size: 12px;
        margin-bottom: 3px;
        color: white;
      }
      
      .footer-link {
        text-decoration: none;
      }
      
}