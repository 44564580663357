@media only screen and (max-width: 800px) {
  .home-landing-container {
    background-image: url("../../assets/pictures/home-large-image.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 350px;
  }

  .home-landing-content {
    margin-top: 25%;
    color: white;
  }

  .home-landing-left-border {
    border-left: solid;
    padding-left: 2%;
  }

  .home-landing-content h1 {
    font-size: 30px;
  }

  .home-landing-content p {
    padding-top: 0px;
    font-size: 12px;
  }

  .home-landing-content img {
    margin-top: -15px;
    width: 20px !important;
    margin-right: 8px;
  }

  .home-landing-icons img {
    color: white;
    width: 10px;
  }

  /* part2 */

  .home-content-risk {
    text-align: center;
    display: block;
    width: fit-content;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
  }

  .home-content-risk img {
    width: 60px;
    margin-top: 0px;
  }

  .home-content-risk h2 {
    font-size: 25px;
    padding-top: 8%;
    padding-bottom: 1%;
    color: rgb(14, 14, 141);
  }
  .home-content-risk p {
    font-size: 15px;
    color: rgb(14, 14, 141);
  }

  .risk-img {
    display: block;
    width: 90%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-content-stability {
    text-align: center;
    display: block;
    width: fit-content;
    margin-top: 25%;
    margin-left: 0px;
    margin-right: auto;
    margin-bottom: 50px;
    color: rgb(14, 14, 141);
  }
  .home-content-stability img {
    width: 60px;
    margin-top: 0px;
  }
  .home-content-stability h2 {
    font-size: 21px !important;
    padding-top: 5%;
    padding-bottom: 3%;
    color: rgb(14, 14, 141);
  }

  .home-content-stability p {
    font-size: 15px;
    color: rgb(14, 14, 141);
  }

  .khalijiImage {
    display: block;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
  }

  .home-container-full-blue {
    background-image: url("../../assets//pictures/towers-pic.png");
    height: 70%;
  }

  .home-content-blue {
    text-align: center;
    color: white;
    height: 180px;
  }

  .home-content-blue h1 {
    padding-top: 2rem;
    padding-bottom: 0rem;
    font-size: 26px;
  }
  .home-content-blue p {
    font-size: 12px;
    padding-bottom: 3rem;
  }

  .home-content-blue img {
    margin-top: -30px;
    width: 70px;
  }

  .home-content-time {
    text-align: center;
    margin-top: 3rem;
    color: rgb(14, 14, 141);
  }
  .home-content-time img {
    width: 70px;
  }
  .home-content-time h2 {
    font-size: 30px;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
  .home-content-time p {
    font-size: 12px;
  }
  .home-horizontal-line {
    height: 2px;
    margin-top: 3rem;
    background-color: rgb(234, 230, 230);
  }
  .home-brainstorm-image {
    width: 100%;
  }
  .home-content-brainstorm {
    text-align: center;
    padding-top: 0%;
    color: rgb(14, 14, 141);
  }
  .home-content-brainstorm img {
    width: 60px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .home-content-brainstorm h2 {
    font-size: 21px;
    margin-top: 10px;
  }
  .home-content-brainstorm p {
    padding-top: 1rem;
    font-size: 12px;
  }

  .home-mission-image {
    margin-top: 5rem;
    color: rgb(14, 14, 141);
    text-align: center;
  }
  .home-mission-image img {
    width: 60px;
    padding-bottom: 2rem;
  }
  .home-mission-image h2 {
    margin-top: -1rem;
    font-size: 18px;
  }
  .home-mission-content {
    margin-top: 1rem;
    color: rgb(14, 14, 141);
  }

  .home-mission-content h3 {
    margin-top: 0rem;
    font-size: 17px;
  }
  .home-mission-content p {
    font-size: 10px;
    margin-bottom: -2.5rem;
  }
  .meeting-image {
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .home-content-meeting img {
    margin-top: -3rem;
    width: 80px;
  }
  .home-content-meeting {
    margin-top: 2rem;
    text-align: center;
    color: rgb(14, 14, 141);
  }
  .home-content-meeting h2 {
    font-size: 25px;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  .home-content-meeting p {
    font-size: 12px;
  }
  .home-people-meeting {
    background-image: url("../../assets//pictures/people-grey-pic.png");
    text-align: center;
    height: 90%;
    color: white;
    margin-top: 3rem;
  }
  .home-people-meeting img {
    width: 70px;
    margin-bottom: -2rem;
  }
  .home-people-meeting h1 {
    font-size: 35px;
    margin-top: 2rem;
  }
  .home-people-meeting p {
    font-size: 11px;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .home-filing-content {
    margin-top: 5rem;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5%;
    height: 200px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    color: rgb(163, 162, 162);
  }
  .home-filing-content h2 {
    font-size: 15px;
    padding-top: 1.3rem;
    padding-bottom: 0rem;
    color: rgb(14, 14, 141);
  }
  .home-filing-content p {
    text-align: center;
    font-size: 12px;
    color: rgb(163, 162, 162);
    font-weight: 500;
  }
  .home-filing-content img {
    width: 40px;
  }

  .home-target-content {
    margin-top: 2rem;
    height: 200px;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    color: rgb(163, 162, 162);
  }
  .home-target-content h2 {
    font-size: 15px;
    padding-top: 1rem;
    padding-bottom: 0rem;
    color: rgb(14, 14, 141);
  }
  .home-target-content p {
    font-size: 12px;
    color: rgb(163, 162, 162);
    font-weight: 500;
    text-align: center;
  }
  .home-target-content img {
    width: 50px;
  }
}
