.aboutUs-background {
  background-image: url("../../assets//pictures/about-us-background.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.aboutUs-header {
  text-align: center;
  color: rgb(14, 14, 141);
  margin-top: 150px;
}
.aboutUs-header h1 {
  font-size: 30px;
  margin-top: 1rem;
  margin-bottom: 50px;
}
.aboutUs-header img {
  width: 95px;
}
.tower-img {
  width: 100%;
  height: 370px;
}
.aboutUs-content p {
  font-size: 14px;
  color: rgb(111, 111, 111);
}
.aboutUs-content-our-team {
  margin-top: 4rem;
  color: rgb(14, 14, 141);
}
.aboutUs-content-our-team img{
  width: 80px;
}
.aboutUs-content-our-team h2 {
  padding-top: 1rem;
  padding-bottom: 0rem;
}
.aboutUs-content-our-team p {
  font-size: 14px;
  color: rgb(111, 111, 111);
}
.aboutUs-dotted-img {
  width: 50%;
  margin-top: 11rem;
  margin-left: 90%;
}
