.services-background {
  background-image: url("../../assets//pictures//beyond\ service\ background.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.services-landing {
  margin-top: 150px;
  text-align: center;
}
.services-landing h1 {
  padding-top: 2rem;
  padding-bottom: 1rem;
  color: rgb(14, 14, 141);
}
.services-landing p {
  font-size: 22px;
  padding-bottom: 3rem;
  color: rgb(171, 169, 169);
}

/* ----------boxing section---------- */

.services-treaty-box {
  text-align: center;
  max-width: 20%;
  max-height: 50%;
  margin-left: 40%;
  background-color: rgb(14, 14, 141) !important;
}

.services-treaty-box p {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: white;
}
.services-facultative-box {
  margin-top: 5%;
  text-align: center;
  max-width: 20%;
  max-height: 40%;
  margin-left: 40%;
  background-color: rgb(14, 14, 141) !important;
}
.services-facultative-box p {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: white;
}
.first-four-boxes {
  text-align: center;
  height: 80%;
}

.first-four-boxes p {
  margin-top: 10%;
  font-weight: 600;
  font-size: 20px;
  color: rgb(111, 111, 111);
}
.services-boxes-view-more {
  text-align: center;
}
.services-boxes-view-more p {
  font-weight: 600;
  font-size: 20px;
  color: rgb(111, 111, 111);
}
.services-modal{
  margin-top: 20px;
}
