@media only screen and (max-width: 1200px) {
    .navbar-desktop {
      display: none;
    }
  
    .navbar-button-anchor {
      text-decoration: none;
    }
  
    .navbar-menu {
      right: 50px;
      width: 25px;
      margin-top: 10px;
      transition: transform 0.5s cubic-bezier(0.8, 0, 0.33, 1);
      position: fixed;
      z-index: 11;
    }
  
    .navbar-layout {
      transition: transform 0.6s cubic-bezier(0.8, 0, 0.33, 1);
      position: fixed;
      width: 200px;
      height: 100vh;
      right: 0;
      left: auto;
      background-color: #ededed;
      opacity: 0.8;
      z-index: 10;
      font-weight: bold;
    }
  
    .navbar-layout li {
      list-style: none;
      text-decoration: none;
      color: black;
      margin-right: -10px;
    }
  
    .navbar-text-home {
      margin-top: 90px;
    }
  
    .navbar-text {
      text-decoration: none;
      color: black;
    }
  
    .navbar-text:hover {
      color: #525252;
    }
  
    .navbar-logo {
      margin-top: 20px;
      width: 150px;
    }
  }