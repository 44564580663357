@media only screen and (min-width: 1200px) {
  /* .navbar-desktop {
    } */
  .navbar-desktop {
    margin-top: 25px;
    background-color: white !important;
    position: absolute;
    width: 100%;
    box-shadow: 0px 0px 14px -2px #afafaf;
  }

  .navbar-desktop img {
    padding-left: 4%;
    padding-top: 2%;
    width: 20%;
  }
  .navbar-box {
    display: block;
    color: rgb(163, 162, 162);
    position: relative;
    width: 80%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-mobile {
    display: none;
  }

  .navbar-layout {
    display: block;
    width: fit-content;

    margin-left: auto;
    height: 45px;
    margin-top: -30px;
    margin-bottom: 0px;
    list-style: none;
    color: rgb(108, 108, 108);
  }

  .navbar-layout li {
    margin-right: 40px;
    display: inline;
    position: relative;
  }

  .navbar-text p {
    display: inline;
    text-decoration: none;
    padding-top: -20px;
  }
  .navbar-contact-us {
    box-shadow: 0.02rem 0.01rem 0.02rem 0.1rem;
  }
  .navbar-text p span:hover {
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 5px;
  }

  .navbar-text {
    color: var(--text-color);
    text-decoration: none;
  }

  .navbar-text:hover {
    color: rgb(14, 14, 141);
  }

  .navbar-services-dropdown-state div li .navbar-services-dropdown {
    display: none;
  }

  .navbar-services-dropdown {
    display: none;
    position: absolute;
    left: -50px;
    width: 200px;
    height: 180px;
    background-repeat: no-repeat;
    z-index: 10;
    padding-top: 20px;
    padding-left: 0px;
    text-align: center;
  }

  .navbar-text-services:hover + .navbar-services-dropdown {
    display: block;
  }

  .navbar-services-dropdown:hover {
    display: block;
  }

  .navbar-services-dropdown:hover ~ .navbar-text-services {
    color: red;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 5px;
  }

  .navbar-services-dropdown hr {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 150px;
  }

  .navbar-service-dropdown li {
    display: block !important;
  }

  .navbar-button button {
    background-color: white;
    border-color: rgb(108, 108, 108);
    color: rgb(14, 14, 141);
    font-family: Bahnschrift;
  }

  .navbar-button:hover {
    background-color: var(--primary-color);
    color: white;
  }
}
