@font-face {
  font-family: Bahnschrift;
  src: url("./assets/fonts/bahnschrift.ttf") format("opentype");
}

h1,
h2,
h3,
p {
  font-family: Bahnschrift;
}
